<template>
  <div>
    <filter-slot
      :filter="FiltersForSlot.filters"
      :total-rows="FiltersForSlot.totalRows"
      :paginate="FiltersForSlot.paginate"
      :start-page="FiltersForSlot.startPage"
      :to-page="FiltersForSlot.toPage"
      :filter-principal="FiltersForSlot.filterPrincipal"
      @reload="$refs['AppointmentTable'].refresh()"
      @reset-all-filters="resetAllFilters"
    >
      <template #table>
        <b-table
          ref="AppointmentTable"
          slot="table"
          class="blue-scrollbar"
          :items="myProvider"
          :fields="visibleFields"
          :busy.sync="isBusy"
          show-empty
          no-provider-filtering
          :per-page="FiltersForSlot.paginate.perPage"
          :current-page="FiltersForSlot.paginate.currentPage"
          :foot-clone="false"
          sticky-header="60vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(responsible)="data">
            <center>
              <b-badge
                id="span_status"
                :class="StatusColorBackground(data.value)"
                pill
              >
                <span v-if="data.item.responsible == 1 && moduleId == 25"
                  >IN AGENT</span
                >
                <span v-else> {{ data.item.responsible }} </span>
              </b-badge>
            </center>
          </template>
          <template #cell(minimum_amount)="{ item }">
            $ {{ parseFloat(item.minimum_price).toFixed(2) }}
          </template>
          <template #cell(paid)="{ item }">
            <div
              :class="{
                'text-success': item.status_paid == 'YES',
                'text-warning': item.status_paid == 'PENDING',
                'text-danger': item.status_paid == 'NO',
              }"
            >
              {{ statusPaid(item) }}
            </div>
          </template>
          <template #cell(files)="data">
            <div class="position-relative">
              <feather-icon
                class="cursor-pointer text-center"
                :class="{
                  'text-secondary': !data.item.status_paid,
                  'text-primary': data.item.files_counter > 0,
                }"
                icon="PaperclipIcon"
                size="20"
                :style="{
                  'pointer-events': data.item.status_paid ? 'auto' : 'none',
                }"
                @click="
                  data.item.status_paid && openVerificationModal(data.item.id)
                "
              />
              <b-badge
                @click="openVerificationModal(data.item)"
                variant="primary"
                class="rounded-circle alert-files cursor-pointer"
                v-if="data.item.files_counter"
                v-b-tooltip.hover.v-primary="'FILES PENDING TO SEND'"
              >
                {{ data.item.files_counter }}
              </b-badge>
            </div>
          </template>
          <template #cell(status)="data">
            <span
              v-if="inSpecialist && currentUser.role_id !== 8"
              :class="[StatusColorText(data.item)]"
            >
               <span v-if="data.item.app_status == 'EXPIRED' || data.item.app_status == 'DELETED'">{{ data.item.app_status }}</span>
              <span v-else>{{ data.item.status }}</span>
            </span>
            <status-app
              v-if="!inSpecialist || currentUser.role_id === 8"
              :data="data"
              :options="statusOptions"
              @addCharge="openAddCharge(data.item)"
              :service="'appointments'"
              @refreshTable="refresh"
            />
          </template>

          <template #cell(client)="{ item }">
            <span class="d-flex align-items-center" style="gap: 5px">
              <icon-status-account :status="item.status_account_id" from-bc />
              <router-link
                target="_blank"
                :to="{
                  name: clientDashboardRouteName,
                  params: {
                    idClient: `${item.account_id}`,
                  },
                }"
              >
                {{ item.client_name }}
              </router-link>
            </span>
            <div class="d-flex align-items-center">
              <span class="d-block" style="margin-left: 18px">
                {{ item.client_account }}
              </span>
            </div>
            <b-badge
              v-if="item.program_id === 3"
              class="font10 ml-1 text-uppercase"
              :variant="
                item.client_type_id == 2 ? 'light-primary' : 'light-warning'
              "
            >
              {{ item.client_type_id == 2 ? "Digital" : "Regular" }}
            </b-badge>
          </template>

          <!-- <template #cell(name_advisor)="{ item }">
            <span>{{ item.name_advisor }} </span>

          </template> -->
          <template #cell(name_advisor)="data">
            <div class="d-flex flex-column">
              <span v-if="Boolean(data.item.name_advisor)"
                >{{ data.item.name_advisor }}
                <feather-icon
                  v-if="hasPermissionAssign"
                  icon="EditIcon"
                  class="clickable text-primary"
                  size="15"
                  @click="addAgentToAplication(data.item)"
                />
              </span>
              <div v-else>
                <b-badge
                  v-if="hasPermissionAssign"
                  variant="warning"
                  pill
                  :class="{ clickable: hasPermissionAssign }"
                  @click="
                    hasPermissionAssign && addAgentToAplication(data.item)
                  "
                  >NO ADVISOR</b-badge
                >
              </div>
            </div>
          </template>

          <template #cell(recommended_by)="{ item }">
            <div class="d-flex flex-column">
              <span>{{ item.recommended_by }}</span>
              <span>{{ item.created_at | myGlobalWithHour }}</span>
            </div>
          </template>

          <template #cell(name_specialist)="{ item }">
            <div class="d-flex justify-content-center">
              <div v-if="!item.name_specialist">
                <feather-icon
                  v-if="
                    (isCeo || isSupervisor || isChief) &&
                    tabName == 'IN PROCESS' &&
                    moduleId == 29
                  "
                  @click="openModalAssign(item)"
                  size="16"
                  icon="UserPlusIcon"
                  class="mr-50 text-success cursor-pointer"
                />
                <span v-else class="text-warning">PENDING</span>
              </div>
              <div v-else class="d-flex justify-content-center">
                <span class="d-block">{{ item.name_specialist }} </span>
                <feather-icon
                  v-if="
                    (isCeo || isSupervisor || isChief) &&
                    tabName == 'IN PROCESS' &&
                    moduleId == 29
                  "
                  class="ml-1 text-warning cursor-pointer"
                  icon="Edit2Icon"
                  @click="openModalAssign(item)"
                />
              </div>
            </div>
          </template>

          <template #cell(date_event)="{ item }">
            <span class="d-block">{{ item.date_event | myGlobal }}</span>
            <span class="d-block">{{ item.hour_event | myFullTime }} </span>
          </template>

          <template #cell(module)="{ item }">
            <span class="text-info">{{ item.module }}</span>
          </template>

          <template #cell(binnacle)="{ item }">
            <tabler-icon
              :badge="item.total_binnacle + item.total_binnacle_files"
              badge-classes="badge-important"
              icon="BrandMessengerIcon"
              size="25"
              class="text-primary cursor-pointer"
              @click="openModalBinnacle(item)"
            />
          </template>
          <template #cell(type_description)="{ item }">
            <div
              :class="[
                item.comment != null ? 'text-primary cursor-pointer' : '',
              ]"
              @click="showComment(item)"
            >
              {{ item.type_description }}
            </div>
          </template>
          <template #cell(form)="{ item }">
            <feather-icon
              v-if="item.status_paid == 'YES'"
              icon="ClipboardIcon"
              size="20"
              class="text-secondary text-center"
              :class="
                item.status_paid
                  ? item.app_status == 'PENDING'
                    ? inSpecialist
                      ? 'text-warning cursor-pointer'
                      : 'text-warning cursor-pointer'
                    : 'text-success cursor-pointer'
                  : 'text-secondary'
              "
              @click="openFormModal(item)"
            />
            <feather-icon
              v-else
              icon="ClipboardIcon"
              size="20"
              class="text-secondary text-center"
            />
          </template>
          <template #cell(amount)="{ item }">
            <span>$ {{ item.amount ? item.amount : "0.00" }}</span>
          </template>
          <template #cell(potencial)="{ item }">
            <template v-if="item.type_id === 145"> - </template>
            <template v-else>
              <tabler-icon
                icon="UrgentIcon"
                v-if="item.is_potencial === 'PENDING' && moduleId == 29"
                @click.prevent="addPotencial(item)"
                size="25"
                class="cursor-pointer text-warning oscillate"
              />
              <template v-else>
                <b-badge
                  :variant="
                    item.is_potencial === 'YES'
                      ? 'success'
                      : item.is_potencial === 'NO'
                      ? 'danger'
                      : 'warning'
                  "
                >
                  {{ item.is_potencial }}
                </b-badge>
              </template>
            </template>
          </template>
          <template #cell(tracking)="{ item }">
            <feather-icon
              class="cursor-pointer text-info"
              icon="ListIcon"
              size="20"
              @click="openTrackingModal(item)"
            />
          </template>
          <template #cell(actions)="{ item }">
            <div
              class="d-flex justify-content-center align-items-center"
              style="gap: 5px"
            >
              <feather-icon
                v-if="
                  inSpecialist &&
                  item.status == 'PENDING' &&
                  item.status_paid == null
                "
                class="cursor-pointer text-danger"
                icon="TrashIcon"
                size="20"
                @click="removeRegister(item)"
              />
              <feather-icon
                v-if="
                  item.specialist_id !== null &&
                  currentUser.user_id == item.specialist_id
                "
                v-b-tooltip.hover.right="'Attend'"
                class="text-success cursor-pointer"
                size="20"
                icon="CheckIcon"
                @click="openModalAttend(item)"
              />
              <feather-icon
                v-if="
                  item.specialist_id !== null &&
                  currentUser.user_id == item.specialist_id
                "
                v-b-tooltip.hover.right="'Reschedule'"
                class="text-warning cursor-pointer"
                size="20"
                icon="CalendarIcon"
                @click="openModalReschedule(item)"
              />
            </div>
          </template>

          <template v-if="[2, 3].includes(appStatus)" #custom-foot>
            <b-tr class="bg-dark fixed-footer">
              <b-th :colspan="indexAmount" />
              <b-th>
                <div class="d-flex flex-column" style="font-size: 16px">
                  <b-badge class="rounded-pill mb-1 ml-auto" variant="info"
                    >Sub Total: $ {{ amountSubTotal | currency }}</b-badge
                  >
                  <b-badge class="rounded-pill ml-auto" variant="info"
                    >Total: $ {{ amountTotal | currency }}</b-badge
                  >
                </div>
              </b-th>
              <b-th colspan="11"></b-th>
            </b-tr>
          </template>
        </b-table>
        <br />
      </template>
    </filter-slot>
    <form-modal
      v-if="showForm"
      :client-data="clientData"
      @closeModal="closeFormModal"
      @refreshTable="refresh"
      :disabled="disabled"
    />
    <tracking-modal
      v-if="showTracking"
      :client-data="clientData"
      @closeModal="closeTrackingModal"
    />
    <ModalBinnacleFinancialAppointment
      v-if="showModalBinnacle"
      @close="closeModalBinnacle"
      :clientInformation="client_information"
    />
    <add-charge
      v-if="showAddCharge"
      :action="1"
      :data="dataClient"
      :all-payment-methods="false"
      send-from="appointments_financial"
      @close="closeAddCharge"
      @refreshTable="refresh"
    />
    <verification-modal
      v-if="ActiveVerificationModal"
      :appointment_id="String(idApp)"
      :program="11"
      :tabName="tabName"
      @close="closeVerificationModal"
    />

    <select-agent-modal
      v-if="openSelectAgentModal"
      :sellers="sellers"
      :appointment_id="appointment_id"
      @close="closeSelectAgentModal"
    />

    <AssignLoanExaminerModal
      v-if="showModalAssign"
      :item="selectedItem"
      @close="showModalAssign = false"
      @refresh="refresh"
    />

    <RescheduleAppointmentModal
      v-if="showModalReschedule"
      :item="selectedItem"
      @close="showModalReschedule = false"
      @refresh="refresh"
    />

    <AttendAppointment
      v-if="showModalAttend"
      :item="selectedItem"
      @close="closeModalAttend"
      @refresh="refresh"
    />

    <b-modal centered v-model="showCommentModal" title="Comment" hide-footer>
      {{ commentSelected }}
    </b-modal>
  </div>
</template>

<script>
// DATA
import Fields from "@/views/commons/components/appointments/data/fields";
import Filters from "@/views/commons/components/appointments/data/filters";

// COMPONENTS
import ChangeStatusP from "@/views/commons/financial-approval/ChangeStatusP.vue";
import SelectAgentModal from "@/views/commons/components/appointments/view/component/modal/SelectAgentModal.vue";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";
import FormModal from "@/views/commons/components/appointments/view/component/modal/FormModal.vue";
import TrackingModal from "@/views/commons/components/appointments/view/component/modal/TrackingModal.vue";
import ModalBinnacleFinancialAppointment from "./modal/ModalBinnacleFinancialAppointment.vue";
import StatusApp from "./modal/StatusApp.vue";
import AddCharge from "@/views/commons/components/applications/views/components/others/AddCharge.vue";
import VerificationModal from "@/views/commons/components/appointments/view/component/modal/VerificationModal.vue";
import GlobalService from "@/service/global";
import AssignLoanExaminerModal from "@/views/commons/components/appointments/view/component/modal/AssignLoanExaminer.vue";
import RescheduleAppointmentModal from "@/views/commons/components/appointments/view/component/modal/RescheduleAppointmentModal.vue";
import AttendAppointment from "@/views/commons/components/appointments/view/component/modal/AttendAppointment.vue";

// SERVICES
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service";

// VUEX
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  components: {
    ChangeStatusP,
    IconStatusAccount,
    FormModal,
    TrackingModal,
    StatusApp,
    AddCharge,
    VerificationModal,
    ModalBinnacleFinancialAppointment,
    SelectAgentModal,
    AssignLoanExaminerModal,
    RescheduleAppointmentModal,
    AttendAppointment,
  },
  data() {
    return {
      openSelectAgentModal: false,
      appointment_id: "",
      Fields,
      items: [],
      FiltersForSlot: {
        filters: Filters,
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
      showForm: false,
      showTracking: false,
      clientData: null,
      showModalBinnacle: false,
      statusOptions: [
        { value: 1, text: "ACCEPTED" },
        { value: 2, text: "REJECTED" },
      ],
      optionsStatusPaid: ["", "YES", "NO"],
      activeModal: false,
      showAddCharge: false,
      dataClient: {},
      app_status: 1,
      idApp: "",
      ActiveVerificationModal: false,
      disabled: false,
      amountT: 0,
      items: [],
      routesModules: {
        5: "debt-solution-appointment",
        6: "credit-experts-appointment",
        7: "boost-credit-appointment",
        20: "connection-appointment",
        22: "customerservice-appointment",
        29: "appointment-financial-approval",
      },
      showModalAssign: false,
      showModalReschedule: false,
      showModalAttend: false,
      selectedItem: null,
      showCommentModal: false,
      commentSelected: null,
    };
  },
  mounted() {
    this.hideFilters();
  },
  computed: {
    ...mapState({
      fieldsTable: (state) => state.DebtSolutionApplications.fields,
      advisors: (state) => state.DebtSolutionApplications.advisors,
    }),
    ...mapGetters({
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      currentUser: "auth/currentUser",
      agentsConnection: "DebtSolutionApplications/G_AGENTS_CONNECTION",
      getRefreshTable: "AlertAppointment/getRefreshTable",
    }),
    hasPermissionAssign() {
      return (
        [20, 22].includes(this.moduleId) &&
        this.isNotAgent &&
        this.$route.matched[2].meta.tabName === "PENDING"
      );
    },
    isNotAgent() {
      return [1, 2, 14, 17].includes(this.currentUser.role_id);
    },
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    visibleFields() {
      this.modifyVisibility(
        "name_specialist",
        this.tabName == "IN PROCESS" || this.tabName == "ACCEPTED"
      );
      this.modifyVisibility(
        "status",
        this.tabName != "ACCEPTED" && this.tabName != "IN PROCESS"
      );
      this.modifyVisibility(
        "responsible",
        !["REJECTED", "expired"].includes(this.tabName)
      );
      this.modifyVisibility(
        "paid",
        this.tabName != "ACCEPTED" && this.tabName != "IN PROCESS"
      );
      this.modifyVisibility("minimum_amount", this.tabName == "PENDING");
      this.modifyVisibility("actions", this.tabName == "PENDING" || this.tabName == "IN PROCESS");
      this.modifyVisibility("potencial", this.tabName == "ACCEPTED");
      this.modifyVisibility(
        "date_event",
        this.tabName == "IN PROCESS" || this.tabName == "ACCEPTED"
      );

      return this.Fields.filter((field) => field.visible);
    },
    isFinancialApproval() {
      return this.moduleId == 29;
    },
    inSpecialist() {
      return [11, 25, 28, 29, 30].includes(this.moduleId);
    },
    amountTotal() {
      return parseFloat(this.amountT);
    },
    amountSubTotal() {
      let suma = 0;
      this.items.map((item) => {
        if (item.amount !== null) suma += parseFloat(item.amount);
      });
      return suma;
    },
    appStatus() {
      return this.$route.meta.statusApp;
    },
    indexAmount() {
      return this.Fields.filter((item) => item.visible).findIndex(
        (item) => item.key == "amount"
      );
    },
  },

  async created() {
    this.tabName = this.$route.meta.tabName;
    const params = {
      module_id: this.moduleId,
      user_id: this.currentUser.user_id,
      role_id: this.currentUser.role_id,
    };
    switch (this.moduleId) {
      case 20:
        await this.getAgentsConnection();
        this.sellers = this.agentsConnection;
        break;
      case 22:
        await this.getAdvisorsAction({ ...params });

        const sellers = await GlobalService.getSellers(22, {
          roles: "[]",
          type: "0",
        });
        this.sellers = sellers.data;
        break;
      default:
        break;
    }
  },
  methods: {
    ...mapActions({
      getAdvisorsAction: "DebtSolutionApplications/getAdvisorsAction",
      getAgentsConnection: "DebtSolutionApplications/A_GET_AGENTS_CONNECTION",
      CHANGE_REFRESH_TABLE: "AlertAppointment/CHANGE_REFRESH_TABLE",
      A_GET_PENDING_APPOINTMENTS: "AppointmentStore/A_GET_PENDING_APPOINTMENTS",
      A_GET_PENDING_POTENTIAL: "PotentialStore/A_GET_PENDING_POTENTIAL",
    }),
    openModalAssign(item) {
      this.selectedItem = item;
      this.showModalAssign = true;
    },
    openModalReschedule(item) {
      this.selectedItem = item;
      this.showModalReschedule = true;
    },
    openModalAttend(item) {
      this.selectedItem = item;
      this.showModalAttend = true;
    },
    closeModalAttend() {
      this.showModalAttend = false;
    },
    async updateStatus(data) {
      try {
        this.addPreloader();
        const { n_option, n_action } = data;
        if (n_option === "StatusPaid") {
          if (n_action === "NO" || n_action === "PENDING") {
            await AppointmentService.updateAppointmentStatus({
              ...data,
              type: "status_paid",
            });
          } else {
            this.openAddCharge(data);
          }
        } else {
          await AppointmentService.updateAppointmentStatus(data);
        }
        this.refresh();
        await this.refreshCountInSidebar();
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.removePreloader();
      }
    },
    closeSelectAgentModal(val) {
      if (val) {
        this.refresh();
      }
      this.openSelectAgentModal = false;
    },
    closeModalBinnacle() {
      this.showModalBinnacle = false;
      this.clientData = null;
      this.refresh();
    },
    addAgentToAplication(item) {
      this.appointment_id = item.id;
      this.openSelectAgentModal = true;
    },

    async myProvider(ctx) {
      try {
        const params = {
          page: ctx.currentPage,
          per_page: ctx.perPage,
          name_text: this.FiltersForSlot.filterPrincipal.model,
          module_id: this.isFinancialApproval
            ? this.getFilterByKey("department").model
            : this.moduleId,
          from: this.getFilterByKey("from").model,
          to: this.getFilterByKey("to").model,
          app_status: this.$route.meta.statusApp,
          current_user_id: this.currentUser.user_id,
          role_id: this.currentUser.role_id,
          type: this.getFilterByKey("type").model,
          status: this.getFilterByKey("status").model,
          order: this.tabName == "IN PROCESS" || this.tabName == "ACCEPTED" ? "paid_at" : "created_at",
        };
        const data = await AppointmentService.search(params);
        this.FiltersForSlot.startPage = data.from;
        this.FiltersForSlot.paginate.currentPage = data.current_page;
        this.FiltersForSlot.paginate.perPage = data.per_page;
        this.FiltersForSlot.totalRows = data.total;
        this.FiltersForSlot.toPage = data.to;
        this.items = data.data;

        this.amountT = data.data.length > 0 ? data.data[0].total : 0;
        // this.refreshCountInSidebar();
        return data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    disabledFile(value) {
      return !value;
    },

    filterColumn(key, bool) {
      const field = this.Fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },

    refresh() {
      this.$refs.AppointmentTable.refresh();
      this.refreshCountInSidebar();
    },

    getFilterByKey(key) {
      const filter = this.FiltersForSlot.filters.find(
        (filter) => filter.key === `${key}`
      );

      return filter;
    },
    hideFilters() {
      let moduleId = this.$route.matched[0].meta.module;
      if (moduleId != 29) {
        let keyDepartment = this.FiltersForSlot.filters.findIndex(
          (item) => item.key == "department"
        );
        if (keyDepartment != -1) {
          this.FiltersForSlot.filters[keyDepartment].visible = false;
        }
        this.FiltersForSlot.filters = this.FiltersForSlot.filters.filter(
          (item) => item.visible
        );
      }
    },
    resetAllFilters() {
      this.FiltersForSlot.filters.forEach((filter) => {
        filter.model = null;
      });
      this.FiltersForSlot.filterPrincipal.model = null;
      this.$refs.AppointmentTable.refresh();
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.Fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.Fields[keyField].visible = isVisible;
      }
    },
    openFormModal(data) {
      this.showForm = true;
      this.clientData = data;
      // this.disabled = !this.inSpecialist || data.app_status != "PENDING";
      this.disabled = data.app_status != "PENDING";
    },
    showComment(item) {
      this.commentSelected = item.comment;
      this.showCommentModal = item.comment != null;
    },
    async removeRegister(item) {
      const params = {
        id: item.id,
        user_id: this.currentUser.user_id,
      };
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.isConfirmed) return;

        this.addPreloader();
        const data = await AppointmentService.removeRegister(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Removed successfully"
          );
          this.$refs.AppointmentTable.refresh();
          await this.refreshCountInSidebar();
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "CXIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openVerificationModal(idApp) {
      this.idApp = idApp;
      this.ActiveVerificationModal = true;
    },
    closeVerificationModal(value) {
      if (value) {
        this.$refs.AppointmentTable.refresh();
      }
      this.ActiveVerificationModal = false;
    },

    closeFormModal() {
      this.showForm = false;
    },
    openTrackingModal(data) {
      this.showTracking = true;
      this.clientData = data;
    },
    closeTrackingModal() {
      this.showTracking = false;
    },
    openModalBinnacle(data) {
      this.showModalBinnacle = true;
      this.client_information = data;
      this.client_information.name_client = data.client_name;
      this.client_information.name_charge = data.type_description;
    },
    openAddCharge(data) {
      this.dataClient = data;
      this.showAddCharge = true;
    },

    closeAddCharge() {
      this.showAddCharge = false;
    },
    async addPotencial(item) {
      try {
        let confirmed = "NO";
        const valid = await this.showQuestionSwal("Is this client potential?");
        if (!valid.dismiss || valid.dismiss == "cancel") {
          if (valid.isConfirmed) {
            confirmed = "YES";
          }
          this.addPreloader();
          const params = {
            id: item.id,
            confirmed,
            module_id: this.moduleId,
            user_id: this.currentUser.user_id,
          };

          const data = await AppointmentService.updateReprogrammingAppointment(
            params
          );
          if(confirmed == "NO") {
            this.refresh();
            this.showSuccessSwal("The client has been marked as not potential");
          } else {
            this.$router.push({ name: "sp-potencial-appointment-in-process" });
            this.showSuccessSwal("The client has been marked as potential");
          }
          await this.A_GET_PENDING_POTENTIAL();
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        this.removePreloader();
      }
    },
    statusPaid(item) {
      return item.status_paid == "PENDING" ? "VERIFYING" : item.status_paid;
    },
    StatusColorText({ status, app_status }) {
      if(app_status == 'EXPIRED' || app_status == 'DELETED') return 'text-danger';

      switch (status) {
        case "PENDING":
          return "text-warning";
        case "ACCEPTED":
          return "text-success";
        case "REJECTED":
          return "text-danger";
        default:
          return "bg-secondary text-light";
      }
    },
    StatusColorBackground(status) {
      switch (status) {
        case "IN ADVISOR":
          return ["bg-light-warning"];
        case "IN SPECIALIST":
          return ["bg-light-primary"];
        case 3:
          return ["bg-light-success"];
        default:
          return "bg-secondary text-light";
      }
    },
    async refreshCountInSidebar() {
      this.A_GET_PENDING_APPOINTMENTS();
    },
    ...mapMutations({
      M_PENDING_APPOINTMENTS: "AppointmentStore/M_PENDING_APPOINTMENTS",
    }),
  },
  watch: {
    getRefreshTable(value) {
      if (value) {
        this.$refs["AppointmentTable"].refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>

<style scoped>
#span_status {
  padding: 5px 10px;
  border-radius: 15px;
  width: 150px;
}
.green {
  background: var(--success);
  color: #f2f2f2;
}
.red {
  background: var(--danger);
  color: #f2f2f2;
}
.yellow {
  background: var(--light-warning);
  color: #f2f2f2;
}
.blue {
  background: #155db1;
  color: #f2f2f2;
}
.active {
  color: var(--success);
  cursor: pointer;
}
.inactive {
  color: var(--secondary);
  cursor: pointer;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
span.item-automatic {
  background-color: #89375f !important;
  border-color: #89375f !important;
}
span.item-manual {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}
.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
}
.alert-files {
  position: absolute;
  top: -10px;
}
</style>
