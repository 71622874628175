export default [
  {
    key: "client",
    label: "Client",
    class: "text-left px-1",
    visible: true,
  },
  {
    key: "name_advisor",
    label: "Advisor",
    class: "text-center px-1",
    visible: true,
  },
  {
    key: "recommended_by",
    label: "Recommended by",
    class: "text-center px-1",
    visible: true,
  },
  {
    key: "type_description",
    label: "Type",
    class: "text-center px-1",
    visible: true,
  },
  {
    key: 'minimum_amount',
    label: 'Minimum amount',
    class: 'text-center',
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  { 
    key: "paid", 
    label: "Paid", 
    thClass: "text-center",
    tdClass: "text-center",
    visible: true 
  },
  { 
    key: "amount",
    label: "amount",
    thClass: "text-right pr-2",
    tdClass: "text-right pr-2",
    thStyle: {
      width: "8%",
    },
    visible: true,
  },
  {
    key: "form",
    label: "Form",
    class: "text-center",
    visible: true,
  },
  {
    key: "name_specialist",
    label: "Assigned to",
    class: "text-center",
    visible: true,
    thStyle: {
      width: "12%"
    }
  },
  {
    key: "date_event",
    label: "Date",
    class: "text-center",
    visible: true,
  },
  {
    key: "responsible",
    label: "Responsible",
    class: "text-center px-1",
    visible: true,
  },
  {
    key: "binnacle",
    label: "Binnacle",
    class: "text-center",
    visible: true,
  },
  {
    key: "potencial",
    label: "Potencial",
    class: "text-center",
    visible: true,
  },

  {
    key: "files",
    label: "File",
    class: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    label: "Tracking",
    class: "text-center",
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    class: "text-center",
    visible: true,
  },
];
