<template>
  <div>
      <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeSuccessfull }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >SUCCESSFUL</b-nav-item
      >
      <b-nav-item
        :to="{
          name: $route.matched[2].meta.routeUnsuccessful,
        }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >UNSUCCESSFUL</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="$route.name" :program_id="programId" />
    </b-card>
  </div>
</template>

<script>
export default {
  computed: {
    programId() {
      return this.$route.matched[1].meta.program;
    },
  },
};
</script>

<style>
</style>
