<template>
  <div>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeCompleted }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >COMPLETED</b-nav-item
      >
      <b-nav-item
        :to="{
          name: $route.matched[2].meta.routeRejected,
        }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >REJECTED</b-nav-item
      >
      <b-nav-item
        :to="{
          name: $route.matched[2].meta.routeExpired,
        }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >EXPIRED</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="$route.name" :program_id="programId" />
    </b-card>
  </div>
</template>

<script>
import ApplicationTable from "@/views/commons/components/appointments/view/component/TableAppointment.vue";
export default {
  components: {
    ApplicationTable,
  },
  methods: {
    assignTable(table) {
      this.table = table;
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[1].meta.module;
    },
    programId() {
      return this.$route.matched[1].meta.program;
    },
  },
};
</script>

<style>
</style>