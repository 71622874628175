<template>
  <b-modal
    v-model="visible"
    size="lg"
    title="Files"
    title-tag="h3"
    modal-class="modal-primary"
    centered
    hide-footer
    @hidden="close"
  >
    <b-container>
      <b-row v-if="!['ACCEPTED', 'expired', 'REJECTED'].includes(tabName)" class="justify-content-end">
        <b-button
          :class="addFile ? 'bg-danger' : 'bg-success'"
          size="md"
          style="border: none; font-size: 15px"
          @click="changeStateAddFile"
        >
          <feather-icon
            :icon="addFile ? 'MinusIcon' : 'PlusIcon'"
            size="15"
          />
          {{ this.addFile ? "Hide" : "Add File" }}
        </b-button> </b-row><br>
      <b-row v-if="addFile">
        <drag-and-drop
          ref="dyd"
          v-model="files"
          class="w-100 mb-1"
          :files-array="files"
        />

        <b-col>
          <b-row class="justify-content-center">
            <b-button
              variant="warning"
              :disabled="files.length === 0"
              @click="uploadFiles"
            >
              <feather-icon icon="UploadCloudIcon" /> UPLOAD
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-table
          ref="FileTable"
          :items="items"
          :fields="fields"
          show-empty
        >
          <template #cell(file_name)="data">
            <feather-icon :icon="fileIcon(data.item.file_type)" />
            <a
              :href="data.item.file_url"
              target="_blank"
              class="ml-1"
            >{{
              data.value
            }}</a>
          </template>
          <template #cell(actions)="data">
            <center>
              <feather-icon
                size="18"
                class="text-danger cursor-pointer"
                icon="Trash2Icon"
                @click="deleteFile(data.item.id)"
              />
            </center>
          </template>
        </b-table>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import DragAndDrop from '@/views/commons/utilities/DragAndDrop.vue';
import { mapState, mapGetters } from 'vuex';
import AppointmentService from '../../../service/appointment.service';

export default {
  components: { DragAndDrop },
  props: {
    appointment_id: { type: String, required: true },
    program: { type: Number, required: true },
    tabName: { type: String, required: true }
  },
  data() {
    return {
      visible: false,
      addFile: false,
      items: [],
      files: [],
      hasFile: false,
    };
  },
  methods: {
    async start() {
      const data = await AppointmentService.getFiles(this.appointment_id);
      this.items = [...data.data];
    },
    async uploadFiles() {
      const formData = new FormData();
      this.files.forEach(file => {
        formData.append('files[]', file, file.name);
      });
      formData.append('program_id', this.program);
      formData.append('idApp', this.appointment_id);
      formData.append('idUser', this.currentUser.user_id);

      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        const result = await AppointmentService.uploadFiles(formData);

        if (result) {
          this.files = [];
          this.changeStateAddFile();
          this.hasFile = true;
          await this.start();
          this.removePreloader();
          this.showSuccessSwal();
        } else {
          this.removePreloader();
          this.showErrorSwal();
        }
      }
    },
    async deleteFile(id) {
      const data = { idFile: id, idUser: this.currentUser.user_id };
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        const result = await AppointmentService.deleteFile(data);
        this.removePreloader();

        if (result) {
          this.showSuccessSwal();
          this.hasFile = true;
          await this.start();
        } else {
          this.showErrorSwal();
        }
      }
    },

    close() {
      this.visible = false;

      this.$emit('close', this.hasFile);
    },
    changeStateAddFile() {
      this.addFile = !this.addFile;
    },
    fileIcon(doc) {
      switch (doc.toLowerCase()) {
        case 'pdf':
          return 'FileTextIcon';
          break;
        case 'docx':
          return 'FileTextIcon';
          break;
        case 'xlsx':
          return 'FileTextIcon';
          break;
        case 'png':
          return 'ImageIcon';
          break;
        case 'jpg':
          return 'ImageIcon';
          break;
        default:
          return 'FileIcon';
          break;
      }
    },
  },
  computed: {
    ...mapState({
      fields: state => state.DebtSolutionApplications.fieldsVerification,
    }),
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async created() {
    this.addPreloader();
    await this.start();
    this.removePreloader();
    this.visible = true;
  },
};
</script>
