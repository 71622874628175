<template>
  <div>
    <b-nav card-header pills class="m-0 mt-1">
      <b-nav-item
        :to="{ name: $route.matched[2].meta.routeSuccessfull }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >SUCCESSFUL</b-nav-item
      >
      <b-nav-item
        :to="{
          name: $route.matched[2].meta.routeUnsuccessful,
        }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >UNSUCCESSFUL</b-nav-item
      >
    </b-nav>
    <b-card class="border-top-info border-3 border-table-radius px-0">
      <ApplicationTable :program_id="programId" @table="assignTable($event)" />
    </b-card>
  </div>
</template>

<script>
import ApplicationTable from "@/views/commons/components/applications/views/TableAplications.vue";
export default {
  components: {
    ApplicationTable,
  },
  methods: {
    assignTable(table) {
      this.table = table;
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[1].meta.module;
    },
    programId() {
      return this.$route.matched[1].meta.program;
    },
  },
};
</script>